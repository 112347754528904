$body-bg: #F6F6F6;
$primary: #fab700;
// $border-radius: 0;
$btn-border-radius: 5px;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;
// $border-radius-sm: 0;
// $border-radius-lg: 0;
$border-color:  #D5D5D5;
$yiq-contrasted-threshold: 190;
$font-family-base: 'Lato', sans-serif;
$font-family-sans-serif: 'Lato', sans-serif;

@mixin scrollbar {
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  scrollbar-width: thin !important;

  &::-webkit-scrollbar { width: 6px; height: 6px }
  &::-webkit-scrollbar-track { background: transparent }
  &::-webkit-scrollbar-thumb { background: rgba(0, 0, 0, 0.3); border-radius: 3px }
  &::-webkit-scrollbar-thumb:hover { background: rgba(0, 0, 0, 0.3); }
}

$utilities: (
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: .25,
      50: .5,
      75: .75,
      100: 1,
    )
  )
);

@import "~bootstrap/scss/bootstrap";
@import "~animate.css/animate.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.vt-scrollbar {
  @include scrollbar();
}

.bg-black {
  background: black;
}

@keyframes flash-bg {
  0% { box-shadow: inset 0 0 0 0 $danger; border-color: $dark }
  50% { box-shadow: inset 0 0 8px 3px $danger; border-color: $danger }
  0% { box-shadow: inset 0 0 0 0 $danger; border-color: $dark }
}

.btn-danger-flashing {
  position: relative;
}

.btn-danger-flashing::after {
  position: absolute;
  content: " ";
  left: -$btn-border-width;
  right: -$btn-border-width;
  top: -$btn-border-width;
  bottom: -$btn-border-width;
  animation: infinite flash-bg 3s linear;
  border-radius: $btn-border-radius;
}

.vt-slider {
  position: relative;
  background-color: #fff;
  width: 10px;
  height: 100%;
  margin: 0 auto;
  border-radius: 5px;
  cursor: pointer;

  &-track {
    position: absolute;
    bottom: 0;
    background-color: $primary;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    pointer-events: none;
  }

  input[type="range"] {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.form-control-no-outline:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 !important;
}

.btn-no-outline:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 !important;
}

svg path {
  fill: currentColor;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

label {
    font-weight: bold;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

$sidebar-menu-width: 70px;
$sidebar-content-width: 330px;
$navbar-height: 58px;

.sidebar {
  width: $sidebar-menu-width;

  &-menu {
    position: fixed;
    top: $navbar-height;
    bottom: 0;
    right: 0;
    background: #F4F4F4;
    width: $sidebar-menu-width;

    button {
      width: 100%;
      background: transparent;
      border: 0;
      outline: none;
      text-align: center;
      height: 70px;
      color: rgba(#000, .3);
      position: relative;

      &:hover {
        background: white;
      }

      svg {
        width: 22px;
        height: 22px;
        margin-bottom: 5px;
      }

      span {
        font-size: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      // indicator
      i {
        position: absolute;
        top: 8px;
        right: 8px;
        background-color: $primary;
        width: 18px;
        height: 18px;
        color: white;
        border-radius: 50%;
        text-align: center;
        line-height: 18px;
        font-size: 10px;
        font-style: normal;
      }
    }
  }

  &-content {
    display: none;
    position: fixed;
    top: $navbar-height;
    bottom: 0;
    right: $sidebar-menu-width;
    background: #fff;
    left: 0;

    &-header {
      height: 50px;
      flex-shrink: 0;
      border-bottom: 1px solid #D5D5D5;
      padding: 0 1em;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-item {
      display: none;
      width: 100%;
      height: 100%;
      flex-direction: column;

      &--visible {
        display: flex;
      }
    }

    &-body {
      @include scrollbar();

      flex: 1;
      overflow-y: auto;
      overscroll-behavior: contain;
    }

    @media (min-width: $sidebar-content-width + $sidebar-menu-width) {
      width: $sidebar-content-width;
      left: auto;
    }
  }

  // Changes when the sidebar is open
  &.opened {
    .sidebar-content {
      display: block;
    }

    .sidebar-menu button.active {
      background: white;
      color: $primary;

      i { display: none }
    }

    @media (min-width: 768px) {
      width: $sidebar-menu-width + $sidebar-content-width;
    }
  }
}

.stream-control {
  position: fixed;
  left: 0;
  right: $sidebar-menu-width;
  bottom: 0;
  background: #fff;
  height: 60px;

  @media (min-width: 768px) {
      right: auto;
      left: calc(50% - #{($sidebar-menu-width + $sidebar-content-width) / 2});
      transform: translateX(-50%);
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      border-radius: 10px 10px 0 0;
  }

  button {
    background: transparent;
    border: 0;
    outline: none;
    width: 80px;
    text-align: center;
    color: #000;

    span {
      font-size: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;

      @media (max-width: 500px) {
        display: none;
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }

    &:disabled {
      opacity: 0.3;
    }

    &:hover:not(:disabled) {
      background-color: rgba(#000, .05);
    }

    &.active {
      background-color: rgba($primary, .2);
      color: $primary;
    }
  }
}

.approve-button {
  color: rgba($primary, .7);
  background: transparent;
  cursor: pointer;
  font-size: large;
  &:hover {
  color: $primary;
  }
}

.reject-button {
  color: rgba(black, .7);
  background: transparent;
  cursor: pointer;
  font-size: large;
  &:hover {
    color: black;
    }
}

@keyframes blink {
  0% { opacity: 0 }
  50% { opacity: 1 }
  0% { opacity: 0 }
}

.blink{
  opacity:0;
  animation: infinite blink 3s linear;
}

.canvas-static-banner {
  position: absolute;
  left: 0;
  bottom: 4vw;
  width: 70%;
  font-size: clamp(6px, 2vw, 24px);
  border-radius: 0 1vw 1vw 0;
  padding: 1vw;
  font-weight: bold;
  word-break: break-word;
}

.canvas-scrolling-banner {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  font-size: clamp(6px, 2vw, 24px);
  font-weight: bold;

  &-item {
    margin-right: 10em;
    white-space: nowrap;
  }
}

.vt-inner-border::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-top: 3px solid $primary;
  border-right: 3px solid $primary;
  border-left: 3px solid $primary;
  border-radius: 0;
  content: " ";
  pointer-events: none;
}

.source-item {
  transition: opacity 500ms linear;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    opacity: 1 !important;

    .source-item-actions {
      visibility: visible !important;
    }
  }

  &-video {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;

    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.view-style-button {
  height: 40px;
  max-width: 51px;
  width: 100%;
  background-color: rgba(#000, .1);
  transition: 0.3s;
  overflow: hidden;
  text-indent: -99999px;
  border: 0;
  outline: none !important;

  &:hover {
      background-color: rgba(#000, .2);
  }

  &.active {
      background-color: rgb(255, 193, 7);
  }
}

.canvas-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  background-color: black;
  overflow: hidden;


  .canvas-item {
    &[draggable="true"] {
      cursor: grab;
    }

    overflow: hidden; // TODO: Check if neccessary

    > div {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: black;
    }

    &-streams {
      position: relative;
      width: 100%;
      height: 100%;

      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  * {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
  }

  &:fullscreen {
    cursor: auto;

    .canvas-fullscreen {
        width: 100vw;
        height: 56.25vw;
        max-height: 100vh;
        max-width: 177.78vh;
        margin: auto;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }


    &.hide {
      cursor: none;
    }

    .stream-control {
      position: fixed;
      margin: 0 auto;
      transform: none !important;
      z-index: 1000;
      transition: opacity 500ms linear;
      background-color: black;
      border: black !important;


      button {
        color: white;

        &.active {
          background-color: black !important;
          color: $primary !important;
        }
      }
    }

    .fullscreen-button {
      right: 10px;
      bottom: 10px;
    }
  }

  &:hover {
    .fullscreen-button {
      opacity: 1;
    }
  }

  .fullscreen-button {
    transition: opacity 500ms linear;
    background-color: black;
    z-index: 1000;
    cursor: pointer;
    color: black;
    position: absolute;
    bottom: 5px;
    right: 5px;

    svg {
      color: white;
    }
  }
}


.canvas-logo {
  position: absolute;
  right: clamp(10px, 2vw, 30px);
  top: clamp(10px, 2vw, 30px);
  width: 100%;
  max-width: clamp(40px, 12vw, 200px);
  height: clamp(40px, 5vw, 80px);
  object-fit: contain;
  object-position: 100% 0;
  pointer-events: none;
}

.canvas-overlay, .canvas-background {
  position: absolute;
  left: 0;
  top: 0;
  object-fit: fill;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.canvas-recording {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 8px 2px red;
  opacity: 0;
  animation: infinite blink 3s linear;
  pointer-events: none;
}

.canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  // Results into 6px on 1280px width.
  grid-column-gap: 0.46875%;
  grid-row-gap: 0.833333%;

  video {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  &-item-name {
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(#000, .8);
    border-radius: 0 0.6em 0 0;
    color: #fff;
    font-size: clamp(12px, 2vw, 24px);
    padding: 0.2em 1em;
    border-left: 0.2em solid var(--color);
  }

  &--grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    &[data-count="2"] {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 2.5fr 1fr;

      :nth-child(1) { grid-area: 2 / 1 / 3 / 2; }
      :nth-child(2) { grid-area: 2 / 2 / 3 / 3; }
    }

    &[data-count="3"] {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);

      :nth-child(1) { grid-area: 3 / 2 / 5 / 4 }
      :nth-child(2) { grid-area: 1 / 1 / 3 / 3 }
      :nth-child(3) { grid-area: 1 / 3 / 3 / 5 }
    }

    &[data-count="4"] {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &[data-count="5"] {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(2, 1fr);

      :nth-child(1) { grid-area: 1 / 1 / 2 / 5 }
      :nth-child(2) { grid-area: 1 / 5 / 2 / 9 }
      :nth-child(3) { grid-area: 1 / 9 / 2 / 13 }
      :nth-child(4) { grid-area: 2 / 3 / 3 / 7 }
      :nth-child(5) { grid-area: 2 / 7 / 3 / 11 }
    }

    &[data-count="6"] {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &[data-count="7"] {
      grid-template-columns: repeat(16, 1fr);
      grid-template-rows: repeat(2, 1fr);

      :nth-child(1) { grid-area: 1 / 1 / 2 / 5; }
      :nth-child(2) { grid-area: 1 / 5 / 2 / 9; }
      :nth-child(3) { grid-area: 1 / 9 / 2 / 13; }
      :nth-child(4) { grid-area: 1 / 13 / 2 / 17; }
      :nth-child(5) { grid-area: 2 / 7 / 3 / 11; }
      :nth-child(6) { grid-area: 2 / 11 / 3 / 15; }
      :nth-child(7) { grid-area: 2 / 3 / 3 / 7; }
    }

    &[data-count="8"] {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }

  &--vertical {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;

    &[data-count="2"] {
      grid-template-columns: repeat(2, 1fr);
    }

    &[data-count="3"] {
      grid-template-columns: repeat(3, 1fr);
    }

    &[data-count="4"] {
      grid-template-columns: repeat(4, 1fr);
    }

    // same layout as we have in the grid
    &[data-count="5"] {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(2, 1fr);

      :nth-child(1) { grid-area: 1 / 1 / 2 / 5 }
      :nth-child(2) { grid-area: 1 / 5 / 2 / 9 }
      :nth-child(3) { grid-area: 1 / 9 / 2 / 13 }
      :nth-child(4) { grid-area: 2 / 3 / 3 / 7 }
      :nth-child(5) { grid-area: 2 / 7 / 3 / 11 }
    }

    &[data-count="6"] {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }

  &--solo-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    &[data-count="2"], &[data-count="3"], &[data-count="4"] {
      grid-template-columns: 3fr 1fr;
      grid-template-rows: repeat(3, 1fr);

      :first-child { grid-area: 1 / 1 / 4 / 2 }
    }

    &[data-count="5"], &[data-count="6"], &[data-count="7"] {
      grid-template-columns: 3fr repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);

      :first-child { grid-area: 1 / 1 / 4 / 2 }
    }
  }

  &--users-screen {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    &[data-count="1"] {
      grid-template-columns: 1fr 3fr;
      grid-template-rows: repeat(3, 1fr);


      :first-child { grid-area: 2 / 1 / 3 / 2 }
      :last-child { grid-area: 1 / 2 / 4 / 3 }
    }

    &[data-count="2"] {
      grid-template-columns: 1fr 3fr;
      grid-template-rows: 1fr repeat(2, 2fr) 1fr;

      :nth-child(1) { grid-area: 2 / 1 / 3 / 2 }
      :nth-child(2) { grid-area: 3 / 1 / 4 / 2 }
      :nth-child(3) { grid-area: 1 / 2 / 5 / 3 }
    }

    &[data-count="3"] {
      grid-template-columns: 1fr 3fr;
      grid-template-rows: repeat(3, 1fr);

      :nth-child(1) { grid-area: 1 / 1 / 2 / 2 }
      :nth-child(2) { grid-area: 2 / 1 / 3 / 2 }
      :nth-child(3) { grid-area: 3 / 1 / 4 / 2 }
      :nth-child(4) { grid-area: 1 / 2 / 4 / 3 }
    }

    &[data-count="4"] {
      grid-template-columns: 1fr 3fr;
      grid-template-rows: repeat(4, 1fr);

      :nth-child(1) { grid-area: 1 / 1 / 2 / 2 }
      :nth-child(2) { grid-area: 2 / 1 / 3 / 2 }
      :nth-child(3) { grid-area: 3 / 1 / 4 / 2 }
      :nth-child(4) { grid-area: 4 / 1 / 5 / 2 }
      :nth-child(5) { grid-area: 1 / 2 / 5 / 3 }
    }

    &[data-count="5"] {
      grid-template-columns: repeat(2, 1fr) 3fr;
      grid-template-rows: repeat(8, 1fr);

      :nth-child(1) { grid-area: 1 / 1 / 3 / 2; }
      :nth-child(2) { grid-area: 3 / 1 / 5 / 2; }
      :nth-child(3) { grid-area: 5 / 1 / 7 / 2; }
      :nth-child(4) { grid-area: 7 / 1 / 9 / 2; }
      :nth-child(5) { grid-area: 4 / 2 / 6 / 3; }
      :nth-child(6) { grid-area: 1 / 3 / 9 / 4; }

    }

    &[data-count="6"] {
      grid-template-columns: repeat(2, 1fr) 3fr;
      grid-template-rows: repeat(8, 1fr);

      :nth-child(1) { grid-area: 1 / 1 / 3 / 2; }
      :nth-child(2) { grid-area: 3 / 1 / 5 / 2; }
      :nth-child(3) { grid-area: 5 / 1 / 7 / 2; }
      :nth-child(4) { grid-area: 7 / 1 / 9 / 2; }
      :nth-child(5) { grid-area: 3 / 2 / 5 / 3; }
      :nth-child(6) { grid-area: 5 / 2 / 7 / 3; }
      :nth-child(7) { grid-area: 1 / 3 / 9 / 4; }
    }

    &[data-count="7"] {
      grid-template-columns: repeat(2, 1fr) 3fr;
      grid-template-rows: repeat(8, 1fr);

      :nth-child(1) { grid-area: 1 / 1 / 3 / 2; }
      :nth-child(2) { grid-area: 3 / 1 / 5 / 2; }
      :nth-child(3) { grid-area: 5 / 1 / 7 / 2; }
      :nth-child(4) { grid-area: 7 / 1 / 9 / 2; }
      :nth-child(5) { grid-area: 2 / 2 / 4 / 3; }
      :nth-child(6) { grid-area: 4 / 2 / 6 / 3; }
      :nth-child(7) { grid-area: 6 / 2 / 8 / 3; }
      :nth-child(8) { grid-area: 1 / 3 / 9 / 4; }
    }

    &[data-count="8"] {
      grid-template-columns: repeat(2, 1fr) 3fr;
      grid-template-rows: repeat(4, 1fr);

      :nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
      :nth-child(2) { grid-area: 2 / 1 / 3 / 2; }
      :nth-child(3) { grid-area: 3 / 1 / 4 / 2; }
      :nth-child(4) { grid-area: 4 / 1 / 5 / 2; }
      :nth-child(5) { grid-area: 1 / 2 / 2 / 3; }
      :nth-child(6) { grid-area: 2 / 2 / 3 / 3; }
      :nth-child(7) { grid-area: 3 / 2 / 4 / 3; }
      :nth-child(8) { grid-area: 4 / 2 / 5 / 3; }
      :nth-child(9) { grid-area: 1 / 3 / 5 / 4; }
    }
  }

  &--solo-screen[data-count="1"] {
    grid-template-columns: 5fr 1fr 4fr;
    grid-template-rows: 1fr 3fr 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;

    :first-child { grid-area: 1 / 1 / 4 / 3 }
    :last-child { grid-area: 2 / 2 / 3 / 4 }
  }
}

.banner-item {
  background-color: #E8E8E8;
  border-radius: 15px;
  margin-bottom: map-get($spacers, 2);
  padding: map-get($spacers, 2) map-get($spacers, 2) map-get($spacers, 2) map-get($spacers, 3);
  font-size: 0.8em;
  display: flex;
  transition: background-color 300ms linear;

  &--active {
    background-color: rgba($primary, .5);

    .banner-item-actions button {
      background-color: transparent;
      color: $primary;
    }
  }

  &--editing {
    background-color: rgba(#8CADF1, .5);

    .banner-item-actions button {
      background-color: #8CADF1;

      &:first-child {
        background-color: transparent;
        color: #7587AE;
      }
    }
  }

  &-text {
    flex: 1;
    align-self: center;
    white-space: pre-line;

    textarea {
      border: 0;
      padding: 0;
      margin: 0;
      outline: none;
      width: 100%;
      resize: none;
      overflow: hidden;
      background: none;
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: map-get($spacers, 3);

    button {
      width: 28px;
      height: 28px;
      background-color: #CACACA;
      border-radius: 8px;
      border: 0;
      margin: 0 0 map-get($spacers, 1) 0;
      outline: none;
      padding: 0;
      transition: background-color 300ms linear;

      &.active {
        background-color: $primary;
        color: #FCDB7F;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}


.form-select[data-selected] {
  background-position: left $form-select-padding-x center, $form-select-bg-position;
  background-size: 16px, $form-select-bg-size;
  padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-indicator-padding;
}

.form-select[data-selected="rtmp://ie.pscp.tv:80/x"] {
  background-image: url('assets/img/icons/social/twitter.svg'), escape-svg($form-select-indicator);
}

.form-select[data-selected="rtmp://a.rtmp.youtube.com/live2"] {
  background-image: url('assets/img/icons/social/youtube.svg'), escape-svg($form-select-indicator);
}

.form-select[data-selected="rtmps://live-api.facebook.com:443/rtmp/"] {
  background-image: url('assets/img/icons/social/facebook.svg'), escape-svg($form-select-indicator);
}
